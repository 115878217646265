const uuidv4 = require("uuid/v4");

let dropAreas       = document.querySelectorAll('.js-drop-area');

let progressBar     = document.getElementById('progress-bar');
let backButton      = document.getElementById('backButton');
// let progressPercent = document.getElementById('progress-percent');
let preloader       = document.getElementById('preloader');
let filesDone       = 0;
let filesToDo       = 0;

let uploadButton = document.querySelector('.js-upload-files');

if (uploadButton) {
    uploadButton.addEventListener('change', (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFiles(e.target.files, e.target.form);
    });
}

[...dropAreas].forEach((dropArea) => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
    });

    ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
    });

    dropArea.addEventListener('drop', handleDrop, false);
});

function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation()
}

function highlight(e) {
    e.currentTarget.classList.add('border-primary', 'bg-primary', 'bg-opacity-5');
}

function unhighlight(e) {
    e.currentTarget.classList.remove('border-primary', 'bg-primary', 'bg-opacity-5');
}

function handleDrop(e) {
    let dt    = e.dataTransfer;
    let files = dt.files;

    handleFiles(files, e.currentTarget);
}

function handleFiles(files, form) {
    let typeError = 0;
    files = [...files];
    files = files.map((file) => {
        if (file.type === 'image/png' || file.type === 'image/jpeg') {
            return file;
        } else {
            typeError++;
        }
    });

    if (typeError) {
        alert('Csak JPG és PNG file-okat tölthetsz fel!');
    }

    initializeProgress(files.length);
    files.map((file) => {
        file['id'] = uuidv4();

        return file;
    });

    files.forEach(previewFile);
    files.forEach((file) => {
        uploadFile(file, form)
    });
}

function previewFile(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
        let img = document.createElement('img');
        img.src = reader.result;
        img.classList.add('w-full', 'object-cover', 'opacity-25');
        img.id = file.id;

        let imgLink = document.createElement('a');
        imgLink.id = 'image_' + img.id;
        imgLink.appendChild(img);
        imgLink.classList.add('relative');

        let preloader = document.createElement('div');
        preloader.innerHTML = '<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g transform="rotate(150 50 50)"><path d="M80 50 A30 30 0 0 1 59.270509831248425 78.53169548885461" fill="none" stroke="#ffffcb" stroke-width="10"></path><path d="M59.270509831248425 78.53169548885461 A30 30 0 0 1 25.72949016875158 67.6335575687742" fill="none" stroke="#fac090" stroke-width="10"></path><path d="M25.72949016875158 67.6335575687742 A30 30 0 0 1 25.729490168751575 32.366442431225806" fill="none" stroke="#ff7c81" stroke-width="10"></path><path d="M25.729490168751575 32.366442431225806 A30 30 0 0 1 59.27050983124842 21.46830451114539" fill="none" stroke="#c0f6d2" stroke-width="10"></path><path d="M59.27050983124842 21.46830451114539 A30 30 0 0 1 80 49.99999999999999" fill="none" stroke="#dae4bf" stroke-width="10"></path><animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="1" repeatCount="indefinite"></animateTransform></g></svg>';
        preloader.classList.add('preloader', 'bg-black', 'bg-opacity-20', 'absolute', 'inset-0', 'flex', 'items-center', 'justify-center');
        imgLink.appendChild(preloader);

        document.getElementById('gallery').appendChild(imgLink);
    }
}

function uploadFile(file, form) {
    let url = form.action;

    let formData = new FormData();
    formData.append('file', file);

    //console.log(file, formData.entries());

    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    axios.post(url, formData, config)
         .then(response => {
             let $link = document.getElementById('image_' + file.id);
             $link.classList.add('group', 'relative', 'uploaded');
             $link.href= response.data.deleteImageRoute;

             let deleteIcon = document.createElement('div');
             deleteIcon.classList.add('opacity-0', 'bg-red-700', 'bg-opacity-50', 'text-white', 'absolute', 'inset-0', 'group-hover:opacity-100', 'flex', 'justify-center', 'items-center');
             deleteIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24"\n' +
                 '                      stroke="currentColor">\n' +
                 '                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"\n' +
                 '                           d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>\n' +
                 '                 </svg>';
             $link.appendChild(deleteIcon);
             let $img = document.getElementById(file.id);
             $img.src = response.data.media;
             $img.classList.remove('opacity-25');

             progressDone();
             if (filesDone === filesToDo) {
                 backButton.classList.remove('opacity-0');
                 // progressPercent.classList.remove('progress-bar-animated');
             } else {
                 backButton.classList.add('opacity-0');
                 // progressPercent.classList.add('progress-bar-animated');
             }
         })
         .catch(error => {
             console.log(error.response.data.errors);
         });

    /*
    fetch(url, {
        method: 'POST',
        body: formData
    })
        .then(response => response.json())
        .then(response => {
            if (!response.success) {
                console.log(response);
            }
            progressDone();
            if (filesDone === filesToDo) {
                preloader.classList.remove('is-loading');
                // progressPercent.classList.remove('progress-bar-animated');
            } else {
                preloader.classList.add('is-loading');
                // progressPercent.classList.add('progress-bar-animated');
            }
        })
        .catch((error) => {
            console.error(error);
        })*/
}

function initializeProgress(numfiles) {
    // progressBar.classList.remove('is-hidden');
    backButton.classList.add('opacity-0');
    filesDone = 0;
    filesToDo = numfiles;
}

function progressDone() {
    filesDone++;
    backButton.classList.remove('opacity-0');
    // let progress = filesDone / filesToDo * 100;
    // progressPercent.style.width = `${progress}%`;
    // progressPercent.setAttribute('aria-valuenow', progress);
}
