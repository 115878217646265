import {tns} from "../../node_modules/tiny-slider/src/tiny-slider";

let $slider = document.querySelector('.js-slider');

if ($slider) {
    let slider = tns({
        container: '.js-slider',
        //mode: 'gallery',
        mouseDrag: true,
        speed: 500,
        autoplay: true,
        autoplayButtonOutput: false,
        //animateDelay: 1500,
        controls: false,
        lazyload: true,
        nav: false,
    });
}
